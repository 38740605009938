import axios from '../utils/axios';

export const login = (username, password) => {
    return axios.post('/login', { username, password });
}

export const createCron = (cron) => {
    return axios.post('/crons', cron);
}

export const deleteCron = (cronId) => {
    return axios.delete(`/crons/${cronId}`);
}

export const updateCron = (cronId, cron) => {
    return axios.put(`/crons/${cronId}`, cron);
}

export const setCronActive = (cronId, active) => {
    return axios.put(`/crons/${cronId}/active`, { active });
}

export const getCrons = () => {
    return axios.get('/crons');
}

export const getLogs = (cursor = null, cron = null, executionTimeStart = null, executionTimeEnd = null) => {
    return axios.get('/logs', {
        params: {
            cursor: cursor || undefined,
            cron: cron || undefined,
            executionTimeStart: executionTimeStart || undefined,
            executionTimeEnd: executionTimeEnd || undefined
        }
    });
}

export const getLog = (logId) => {
    return axios.get(`/logs/${logId}`);
}

export const getLogResponse = (logId) => {
    return axios.get(`/logs/${logId}/response`);
}

export const getAppSettings = () => {
    return axios.get(`/settings`);
}

export const updateAppSettings = (timeout, alertEmail1, alertEmail2) => {
    return axios.put(`/settings`, { timeout, alertEmail1, alertEmail2 });
}