import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem('themeMode');
    return savedMode || 'light';
  });

  useEffect(() => {
    localStorage.setItem('themeMode', mode);
  });

  const theme = useMemo(() => createTheme({
    palette: {
      mode: mode,
      primary: { main: '#5D72FE' },
      secondary: { main: '#5D72FE' },
      background: {
        default: mode === 'light' ? '#E3E3E3' : '#1c1e21',
        paper: mode === 'light' ? '#ffffff' : '#2d2f33',
        default_light: mode === 'light' ? '#f5f5f5' : '#3a3a3a', //f5f5f5
      },
      text: { primary: mode === 'light' ? '#333' : '#ffffff' },
      divider: mode === 'light' ? '#B8B8B8' : '#B8B8B8',
      icon: '#9a9a9a',
      hover: mode === 'light' ? '#E3E3E3' : '#2c2c2c',
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: mode === 'light' ? '#ffffff' : '#2d2f33',
            fontWeight: 'bold',
            fontSize: '0.675rem',
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              backgroundColor: mode === 'light' ? '#f0f0f0' : '#3a3a3a',
              '& input:-webkit-autofill': {
                '-webkit-box-shadow': `0 0 0 100px ${mode === 'light' ? '#f0f0f0' : '#3a3a3a'} inset`,
                '-webkit-text-fill-color': mode === 'light' ? '#333' : '#ffffff',
              },
              '& input:-webkit-autofill:focus': {
                '-webkit-box-shadow': `0 0 0 100px ${mode === 'light' ? '#f0f0f0' : '#3a3a3a'} inset`,
                '-webkit-text-fill-color': mode === 'light' ? '#333' : '#ffffff',
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none'
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            textTransform: 'none',
            padding: '12px 24px',
            fontWeight: 'bold',
          },
        },
      },
    },
  }), [mode]);

  const toggleMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleMode }}>
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);