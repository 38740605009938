import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Drawer, TextField, Grid, useTheme } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import moment from 'moment';
import { getLogResponse } from '../services/api';

const LogOverlay = ({ selectedLog, timezonePerspective }) => {

    const [log, setLog] = useState(null);
    const [isResponseLoading, setIsResponseLoading] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);

    useEffect(() => {
        if (selectedLog) {
            setLog(selectedLog);

            setIsResponseLoading(true);
            getLogResponse(selectedLog.id).then((response) => {
                setLog(prevLog => {
                    return {
                        ...prevLog,
                        response: response.data.response,
                    };
                }); 
            }).catch((error) => {
                console.error('Error fetching log response', error);
            }).finally(() => {
                setIsResponseLoading(false);  
            });
        }
    }, [selectedLog]);

    var formattedDate = 'No Data';

    if (log?.executionTime) {
      try {
        formattedDate = moment(log.executionTime).tz(timezonePerspective).format('YYYY-MM-DD HH:mm:ss');
      }catch(error) {
        formattedDate = moment(log.executionTime).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');
      }
    }

    const InfoItem = ({ label, value }) => (
        <Box sx={{ p: 0.25, borderRadius: 1 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>{label}</Typography>
            <Typography variant="body1" sx={{ fontWeight: 'medium' }}>{value}</Typography>
        </Box>
    );

    const theme = useTheme();

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };
    
    return (
        <Box
            sx={{
                height: selectedLog ? '100%' : '0',
                backgroundColor: theme.palette.background.default_light,//'background.paper',
                transition: 'height 0.3s ease-in-out',
                // borderTopLeftRadius: 16,
                // borderTopRightRadius: 16,
                boxShadow: 7,
                // overflow: 'visible',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 1,
                    cursor: 'pointer',
                }}
                // onClick={() => onToggle(!isOpen)}
            >
                <IconButton>
                    <DragHandleIcon />
                </IconButton>
            </Box>
                <Box sx={{ padding: 2, overflow: 'auto' }}>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                        <Grid item xs={6}>
                            <InfoItem label="Cron" value={log?.cron?.name ?? 'No Data'} />
                        </Grid>
                        <Grid item xs={6}>
                            <InfoItem label="Date" value={formattedDate} />
                        </Grid>
                        <Grid item xs={6}>
                            <InfoItem label="Duration" value={log?.duration ?? 'No Data'} />
                        </Grid>
                        <Grid item xs={6}>
                            <InfoItem label="Status" value={log?.status ?? 'No Data'} />
                        </Grid>
                    </Grid>
                    <Box 
                        className="flex-grow flex flex-col"
                        sx={{
                            position: isFullscreen ? 'fixed' : 'relative',
                            top: isFullscreen ? '0' : 'auto',
                            left: isFullscreen ? '0' : 'auto',
                            width: isFullscreen ? '100%' : '100%',
                            height: isFullscreen ? '100%' : (selectedLog ? '50%' : '0px'),
                            backgroundColor: isFullscreen ? 'rgba(0, 0, 0, 0.8)' : 'transparent',
                            zIndex: 9000,
                        }}
                    >
                        <TextField
                            multiline
                            rows={7}
                            variant="outlined"
                            fullWidth
                            InputProps={{ 
                                readOnly: true,
                                endAdornment: (
                                    <IconButton
                                        onClick={toggleFullscreen}
                                        sx={{ position: 'absolute', top: 8, right: 8 }}
                                    >
                                        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                                    </IconButton>
                                ),
                            }}
                            value={isResponseLoading ? 'Loading...' : (log?.response ?? 'No Data')}
                            className="bg-gray-50 flex-grow"
                        />
                    </Box>
                </Box>
        </Box>
    );
};

export default LogOverlay;