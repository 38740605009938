import { useCallback, useState } from "react";
import useBottomDetection from "./useBottomDetection";

export const useInfiniteScroll = (fetchNewItems) => {
    const [items, setItems] = useState([]);
    const [cursor, setCursor] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const fetchNext = useCallback(async () => {
        if (isLoading || !hasMore) return;

        setIsLoading(true);

        try {
            const {
                newItems,
                newCursor,
                hasMore: hasMoreItems
            } = await fetchNewItems(cursor);

            setItems(prevItems => [...prevItems, ...newItems]);
            setCursor(newCursor);
            setHasMore(hasMoreItems);
        }catch(error) {
            console.error('Error fetching new items:', error);
        }finally {
            setIsLoading(false);
        }
    }, [isLoading, hasMore, cursor, fetchNewItems]);

    const containerRef = useBottomDetection(fetchNext, { bottomOffset: 200 });

    const restartScroll = useCallback(async() => {
        setIsLoading(true);
        setHasMore(true);

        try {
            const {
                newItems,
                newCursor,
                hasMore: hasMoreItems
            } = await fetchNewItems(null);

            setItems(newItems);
            setCursor(newCursor);
            setHasMore(hasMoreItems);
        } catch(error) {
            console.error('Error restarting scroll:', error);
        } finally {
            setIsLoading(false);
        }
    }, [fetchNewItems]);

    return {
        items,
        setItems,
        hasMore,
        isLoading,
        restartScroll,
        containerRef
    }
}