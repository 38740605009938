import React, { useCallback, useEffect, useState } from 'react';
import { Box, Paper, Typography, TextField, Button, Snackbar, Container } from "@mui/material";
import { getAppSettings, updateAppSettings } from '../services/api';

const SettingsPage = () => {
    const [timeout, setTimeout] = useState('');
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    useEffect(() => {

        const fetchAppSettings = async () => {
            try {
                const response = await getAppSettings();
                const settings = response.data;
                setTimeout(settings.timeout);
                setEmail1(settings.alertEmail1);
                setEmail2(settings.alertEmail2);
            } catch (error) {
                console.log(error);
            }
        };

        fetchAppSettings();

    }, []);

    const handleSubmit = useCallback(async(e) => {
        e.preventDefault();

        try {
            await updateAppSettings(timeout, email1, email2);
            setSnackbarOpen(true);
        } catch (error) {
            console.log(error);
        }
    });

    return (
        <Container maxWidth="md" sx={{ 
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        }}>
            <Paper 
                elevation={4} 
                sx={{ 
                    borderRadius: 4, 
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                    <Typography variant="h5" style={{ fontWeight: 'bold' }}>Settings</Typography>
                </Box>
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <TextField
                        label="Timeout (in seconds)"
                        type="number"
                        value={timeout}
                        onChange={(e) => setTimeout(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="Alert Email 1"
                        type="email"
                        value={email1}
                        onChange={(e) => setEmail1(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label="Alert Email 2"
                        type="email"
                        value={email2}
                        onChange={(e) => setEmail2(e.target.value)}
                        fullWidth
                    />
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                        Save Settings
                    </Button>
                </Box>
            </Paper>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={1000}
                onClose={() => setSnackbarOpen(false)}
                message="Settings saved successfully"
            />
        </Container>
    );
};

export default SettingsPage;