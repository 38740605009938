import { Box, Breadcrumbs, IconButton, InputAdornment, Link, List, ListItem, ListItemText, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import LogRow from '../components/LogRow';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LogOverlay from '../components/LogOverlay';
import { createCron, deleteCron, getCrons, getLogs, setCronActive, updateCron } from '../services/api';
import { formatDuration } from '../utils/format';
import { useInfiniteScroll } from '../hooks/useInfiniteScroll';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import moment from 'moment-timezone';
import TimezoneSelector from '../components/TimezoneSelector';
import CronsPanel from '../components/CronsPanel';
import LogsPanel from '../components/LogsPanel';


const Dashboard = () => {

    const [selectedCron, setSelectedCron] = useState(null);
    const [selectedLog, setSelectedLog] = useState(null);

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            gap: 5,
            p: 5,
            height: '100vh', // Adjust this value based on your layout
            overflow: 'hidden', // Prevent scrolling on the main container
        }}>

            <CronsPanel
                selectedCron={selectedCron}
                setSelectedCron={setSelectedCron}
            />

            <LogsPanel 
                selectedLog={selectedLog}
                setSelectedLog={setSelectedLog}
                selectedCron={selectedCron}
            />

        </Box>
    );
}

export default Dashboard
