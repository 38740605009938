import axios from 'axios';
import { useAuth } from '../context/AuthProvider';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:4000',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setAuthToken = (token) => {
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.common['Authorization'];
  }
}

let logoutCallback = () => console.warn('Logout callback not set');

export const setLogoutCallback = (callback) => {
  logoutCallback = callback;
};

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      logoutCallback();
    }
    return Promise.reject(error);
  }
);

export default instance;