import React, { createContext, useEffect, useState, useContext } from 'react'
import { setAuthToken, setLogoutCallback } from '../utils/axios';

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState(null);

    const setToken = (token) => {
        setAuthState(true);
        localStorage.setItem('token', token);
        setAuthToken(token);
    }

    const clearToken = () => {
        setAuthState(false);
        localStorage.removeItem('token');
        setAuthToken(null);
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setToken(token);
        }

        setLogoutCallback(clearToken);
    }, []);

    return (
        <AuthContext.Provider value={{ authState, setToken, clearToken }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);
