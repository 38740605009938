import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import Dashboard from '../pages/DashboardPage';
import AuthenticationPage from '../pages/AuthenticationPage';
import { useAuth } from '../context/AuthProvider';
import NotificationsPage from '../pages/NotificationsPage';
import SettingsPage from '../pages/SettingsPage';

const MainContent = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  const { authState } = useAuth();

  return (
    <Box component="main" sx={{ 
      flexGrow: 1, 
      display: 'flex', 
      flexDirection: 'column'
    }}>
      <Routes>
        <Route
          path="/dashboard"
          element={authState ? <Dashboard /> : <Navigate to="/login" />}
        />
        <Route 
          path="/notifications"
          element={authState ? <NotificationsPage /> : <Navigate to="/login" />}
        />
        <Route 
          path="/settings"
          element={authState ? <SettingsPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/login"
          element={authState ? <Navigate to="/dashboard" /> : <AuthenticationPage />}
        />
        <Route
          path="/"
          element={<Navigate to={authState ? "/dashboard" : "/login"} />}
        />
      </Routes>
    </Box>
  );
};

export default MainContent;