import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteScroll } from '../hooks/useInfiniteScroll';
import { getLog, getLogs } from '../services/api';
import { formatDuration } from '../utils/format';
import moment from 'moment-timezone';
import { Box, Breadcrumbs, IconButton, Link, Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LogRow from './LogRow';
import LogOverlay from './LogOverlay';
import TimezoneSelector from './TimezoneSelector';

const mapLog = (log) => {
    log.id = log._id;
    delete log._id;

    log.date = new Date(log.executionTime);

    log.duration = formatDuration(log.executionDuration);

    if (log.cron) {
        log.cron.id = log.cron._id;
        delete log.cron._id;
    }

    return log;
};

const LogsPanel = ({
    selectedLog,
    setSelectedLog,
    selectedCron,
}) => {

    const {
        items: logs,
        setItems: setLogs,
        hasMore: hasMoreLogs,
        isLoading: isLogsLoading,
        restartScroll: restartLogsScroll,
        containerRef: logsTableRef
    } = useInfiniteScroll(async (cursor) => {
        const response = await getLogs(cursor, selectedCron?.id, null, null);

        const newLogs = response.data.logs.map(mapLog);
        const newCursor = response.data.nextCursor;
        const hasMore = response.data.hasMore;

        return { newItems: newLogs, newCursor, hasMore };
    });

    useEffect(() => {
        restartLogsScroll();
    }, [selectedCron]);

    //Interval to pool new items:


    // Ref to hold the interval ID
    const intervalRef = useRef(null);
    // state for checking if interval is still running
    const [isIntervalRunning, setIsIntervalRunning] = useState(false);

    useEffect(() => {
        // Clear the interval if it exists
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        
        // Start the interval for polling new logs
        intervalRef.current = setInterval(async () => {
            if (isIntervalRunning) {
                return;
            }

            setIsIntervalRunning(true);

            if (logs.length > 0) {
                const firstLogDate = new Date(logs[0].executionTime);  // Ensure it's a Date object
                const infinitesimalDate = new Date(firstLogDate.getTime() + 1);
                const response = await getLogs(null, selectedCron?.id, infinitesimalDate, null);

                const newLogs = response.data.logs.map(mapLog);
                var shouldUpdate = newLogs.length > 0;

                const logsArray = [...newLogs, ...logs];

                await Promise.all(
                    logsArray.map(async(log, index) => {
                        if (log.status === 'PENDING') {
                            const response = await getLog(log.id);
                            const fetchedLog = mapLog(response.data);
                            if (fetchedLog.status !== 'PENDING') {
                                logsArray[index] = fetchedLog;
                                shouldUpdate = true;
                            }
                        }
                    })
                )

                if (shouldUpdate) {
                    setLogs(logsArray);
                }
            }else{
                const response = await getLogs(null, selectedCron?.id, null, null);

                const newLogs = response.data.logs.map(mapLog);

                // Prepend the new logs to the existing logs
                if (newLogs.length > 0) {
                    setLogs(prevLogs => [...newLogs, ...prevLogs]);
                }
            }

            setIsIntervalRunning(false);
        }, 1000); // Every second

        // Clean up the interval on component unmount or when selectedCron changes
        return () => clearInterval(intervalRef.current);
    }, [selectedCron, logs, setLogs, isIntervalRunning]);

    //-----


    const [timezonePerspective, setTimezonePerspective] = useState(moment.tz.guess());
    // Anchor element is used for timezone selection dropdown
    const [anchorEl, setAnchorEl] = useState(null);

    const handleLogClick = (log) => {
        if (selectedLog?.id === log?.id) {
            setSelectedLog(null);
        } else {
            setSelectedLog(log);
        }
    };

    const handleTimezoneClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTimezoneClose = () => {
        setAnchorEl(null);
    };

    const handleTimezoneChange = (event) => {
        setTimezonePerspective(event.target.value);
        //after 0.5s close the popover
        setTimeout(() => {
            handleTimezoneClose();
        }, 500);
    };

    return (
        <>
            <Paper
                elevation={4}
                sx={{
                    flexGrow: 3,
                    flexBasis: 0,
                    borderRadius: 4,
                    // p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%', // Set the Paper to full height
                    // position: 'relative', // This establishes the positioning context
                    overflow: 'hidden',
                }}
            >
                <Box sx={{ p: 2, display: 'flex', position: 'relative', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Box>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>Logs</Typography>
                        {selectedCron?.id && (
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                sx={{
                                    mt: 1,
                                    position: 'absolute',
                                    // top: '100%', // Positions below the "Logs" heading
                                    // left: 0, 
                                    mt: 0.5 // Adjust the margin as needed
                                }}
                            >
                                <Link
                                    color="inherit"
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                    underline="hover"
                                >
                                    Crons
                                </Link>
                                <Typography color="textPrimary">{selectedCron.name}</Typography>
                            </Breadcrumbs>
                        )}
                    </Box>
                    <Box>
                        <IconButton
                            size="medium"
                            color="primary"
                            onClick={handleTimezoneClick}
                            title="Change timezone perspective"
                        >
                            <TravelExploreIcon fontSize="large" />
                        </IconButton>
                        <IconButton
                            size="medium"
                            color="primary"
                            onClick={() => { alert('Feature not yet implemented!') }}
                            title="Filter logs by date range"
                        >
                            <DateRangeIcon fontSize="large" />
                        </IconButton>
                    </Box>
                </Box>
                <TableContainer
                    sx={{ flexGrow: 1, overflow: 'auto' }}
                    ref={logsTableRef}
                >
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell width="25%">CRON</TableCell>
                                <TableCell width="25%">DATE</TableCell>
                                <TableCell width="25%">DURATION</TableCell>
                                <TableCell width="25%">STATUS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logs.map((log, index) => (
                                <LogRow key={index} log={log} index={index} timezonePerspective={timezonePerspective} selected={selectedLog && log?.id === selectedLog?.id} onClick={() => handleLogClick(log)} />
                            ))}
                        </TableBody>
                    </Table>
                    {logs.length === 0 && (
                        <Box
                            sx={{
                                position: 'relative',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            <Typography variant="body1" color="text.secondary">
                                No Data
                            </Typography>
                        </Box>
                    )}
                </TableContainer>
                <LogOverlay
                    selectedLog={selectedLog}
                    timezonePerspective={timezonePerspective}
                />
            </Paper>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleTimezoneClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: { width: '300px' },
                }}
            >
                <Box sx={{ p: 2 }}>
                    <TimezoneSelector
                        name="timezone perspective"
                        value={timezonePerspective}
                        onChange={handleTimezoneChange}
                    />
                </Box>
            </Popover>
        </>
    )
}

export default LogsPanel
