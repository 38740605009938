import moment from "moment";

export const formatDuration = (milliseconds) => {
  if (milliseconds == null) {
    return null;
  }

  const duration = moment.duration(milliseconds);

  if (duration.hours() > 0) {
    return `${duration.hours()} hour${duration.hours() !== 1 ? 's' : ''}`;
  } 
  if (duration.minutes() > 0) {
    return `${duration.minutes()} minute${duration.minutes() !== 1 ? 's' : ''}`;
  } 
  if (duration.seconds() > 0) {
    return `${duration.seconds()} second${duration.seconds() !== 1 ? 's' : ''}`;
  }
  if (duration.milliseconds() > 0) {
    return `${duration.milliseconds()} millisecond${duration.milliseconds() !== 1 ? 's' : ''}`;
  }

  return "Instant"; // Handle the case for 0 milliseconds
};

export const formatAgo = (date, timezone) => {
  const tz = timezone ? timezone : moment.tz.guess();

  const now = moment().tz(tz);
  const logDate = moment.tz(date, tz);

  const differenceInMinutes = now.diff(logDate, 'minutes');
  const differenceInHours = now.diff(logDate, 'hours');
  const differenceInDays = now.diff(logDate, 'days');

  if (differenceInMinutes < 1) {
    return 'Now';
  } else if (differenceInMinutes < 60) {
    return `${differenceInMinutes} min${differenceInMinutes !== 1 ? 's' : ''} ago`;
  } else if (differenceInDays === 1 || (differenceInHours < 24 && now.day() !== logDate.day())) {
    return `Yesterday at ${logDate.format('HH:mm')}`;
  } else if (differenceInDays === 2 || (differenceInHours < 48 && now.day() - logDate.day() === 2)) {
    return `2 days ago at ${logDate.format('HH:mm')}`;
  } else if (differenceInDays < 7) {
    return `${logDate.format('dddd')} at ${logDate.format('HH:mm')}`;
  } else if (differenceInDays < 365) {
    return logDate.format('MMM DD, HH:mm');
  } else {
    return logDate.format('YYYY-MM-DD HH:mm:ss');
  }
};

export const sanitizeModel = (model) => {
  if (model._id) {
    model.id = model._id;
    delete model._id;
  }
  delete model._v;
  // Add more cleaning steps as needed
  return model;
};