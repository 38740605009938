import React, { useEffect, useState } from 'react'
import { createCron, deleteCron, getCrons, setCronActive, updateCron } from '../services/api';
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CronRow from './CronRow';
import CronModal from './CronModal';
import AddIcon from '@mui/icons-material/Add';

const mapCron = (cron) => {

    cron.id = cron._id;
    delete cron._id;

    delete cron._v;

    return cron;
};

const sortCronsByFrequency = (crons, direction = 'asc') => {
    return [...crons].sort((a, b) => {
      const freqA = a.frequency ?? Number.MAX_SAFE_INTEGER;
      const freqB = b.frequency ?? Number.MAX_SAFE_INTEGER;
      
      if (direction === 'asc') {
        return freqA - freqB;
      } else {
        return freqB - freqA;
      }
    });
  };

const CronsPanel = ({
    selectedCron,
    setSelectedCron,
}) => {

    const [crons, setCrons] = useState([]);
    const [isCronModalOpen, setIsCronModalOpen] = useState(false);

    // Fetch crons from the server
    useEffect(() => {

        const fetchCronData = async () => {
            try {
                const response = await getCrons();
                const fetchedCrons = response.data.crons.map(mapCron);
                const sortedCrons = sortCronsByFrequency(fetchedCrons, 'desc');
                setCrons(sortedCrons);
            } catch (error) {
                console.log(error);
            }
        };

        fetchCronData();

    }, []);

    const handleCronClick = (cron) => {
        if (selectedCron?.id === cron?.id) {
            setSelectedCron(null);
        } else {
            setSelectedCron(cron);
        }
    };

    const handleAddCron = async (newCron) => {
        const response = await createCron(newCron);
        const cronsWithNew = [...crons, mapCron(response.data)];
        const sortedCrons = sortCronsByFrequency(cronsWithNew, 'desc');
        setCrons(sortedCrons);
    };

    const handleDeleteCron = async (cronId) => {
        await deleteCron(cronId);
        setCrons(prevCrons => prevCrons.filter(cron => cron.id !== cronId));
    };

    const handleUpdateCron = async (cronId, updatedCron) => {
        await updateCron(cronId, updatedCron);
        const cronsWithUpdated = crons.map(cron => {
            if (cron.id === cronId) {
                return updatedCron;
            }
            return cron;
        });
        const sortedCrons = sortCronsByFrequency(cronsWithUpdated, 'desc');
        setCrons(sortedCrons);
    };

    const handleCronActiveToggle = async (cronId, active) => {
        await setCronActive(cronId, active);
        setCrons(prevCrons => prevCrons.map(cron => {
            if (cron.id === cronId) {
                cron.active = active;
            }
            return cron;
        }));
    };

    return (
        <Paper
            elevation={4}
            sx={{
                flexGrow: 2,
                flexBasis: 0,
                borderRadius: 4,
                // p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%', // Set the Paper to full height
                overflow: 'hidden',
            }}
        >
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>Crons</Typography>
                <IconButton size="medium" color="primary" onClick={() => setIsCronModalOpen(true)}>
                    <AddIcon fontSize="large" />
                </IconButton>
            </Box>
            <TableContainer
                sx={{ flexGrow: 1, overflow: 'auto' }}
            >
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell width="30%">NAME</TableCell>
                            <TableCell width="30%">EXECUTION</TableCell>
                            <TableCell width="30%">LAST EXECUTION</TableCell>
                            <TableCell width="10%"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {crons.map((cron, index) => (
                            <CronRow
                                key={index}
                                cron={cron}
                                index={index}
                                selected={selectedCron && cron?.id === selectedCron?.id}
                                onClick={() => handleCronClick(cron)}
                                onDeleteClick={() => handleDeleteCron(cron.id)}
                                onSaveClick={(updatedCron) => handleUpdateCron(cron.id, updatedCron)}
                                onActiveToggle={(cronId, active) => handleCronActiveToggle(cron.id, active)}
                            />
                        ))}
                    </TableBody>
                </Table>
                {crons.length === 0 && (
                    <Box
                        sx={{
                            position: 'relative',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography variant="body1" color="text.secondary">
                            No Data
                        </Typography>
                    </Box>
                )}
            </TableContainer>

            <CronModal
                open={isCronModalOpen}
                handleClose={() => setIsCronModalOpen(false)}
                handleAdd={handleAddCron}
            />
        </Paper>
    )
}

export default CronsPanel
