import React from 'react';
import { Drawer, Box, Divider, IconButton, Stack } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '../context/ThemeProvider';
import LightModeIcon from '@mui/icons-material/LightMode';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useAuth } from '../context/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';

const Sidebar = ({ width }) => {
    const { mode, toggleMode } = useTheme();
    const { clearToken } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const isActive = (path) => location.pathname === path;

    return (
        <Drawer
            open={true}
            variant="persistent"
            anchor="left"
            sx={{
                width: width,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: width,
                    boxSizing: 'border-box',
                    border: 'none',
                },
            }}>
            <Stack
                spacing={3}
                sx={{
                    height: '100%',
                    p: 3,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Stack spacing={3} alignItems="center">
                    <img src={mode === 'light' ? 'logo.svg' : 'logo_dark.svg'}  alt="Logo" style={{ width: '100%', padding: 5 }} />
                    <Divider sx={{ width: '100%', my: 1 }} />
                    <IconButton 
                        aria-label="dashboard"
                        onClick={() => navigate('/dashboard')}
                        sx={{
                            color: isActive('/dashboard') ? 'primary.main' : 'icon',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        <DashboardIcon />
                    </IconButton>
                    <IconButton 
                        aria-label="show notifications"
                        onClick={() => navigate('/notifications')}
                        sx={{
                            color: isActive('/notifications') ? 'primary.main' : 'icon',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        <NotificationsActiveIcon />
                    </IconButton>
                    <IconButton 
                        aria-label="show settings"
                        onClick={() => navigate('/settings')}
                        sx={{
                            color: isActive('/settings') ? 'primary.main' : 'icon',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        <SettingsIcon />
                    </IconButton>
                    <IconButton 
                        onClick={toggleMode} 
                        aria-label="toggle dark/light mode"
                        sx={{
                            color: 'icon'
                        }}
                    >
                        {mode === 'dark' ? <ModeNightIcon /> : <LightModeIcon />}
                    </IconButton>
                </Stack>
                <IconButton aria-label="logout" onClick={clearToken}>
                    <LogoutIcon />
                </IconButton>
            </Stack>
        </Drawer>
    );
};

export default Sidebar;