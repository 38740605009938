import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTheme } from '../context/ThemeProvider';
import { useAuth } from '../context/AuthProvider';
import { login } from '../services/api';

function AuthenticationPage() {
    const { mode } = useTheme();
    const { setToken } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        //timeout 0.5 s
        await new Promise((resolve) => setTimeout(resolve, 500));

        try {
            const response = await login(username, password);
            if (response.data && response.data.token) {
                setToken(response.data.token);
            } else {
                setError('Invalid response from server');
            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                setError(error.response.data.message);
            }else{
                setError('An error occurred. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Grid container sx={{ flexGrow: 1, height: '100vh' }}>
            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{
                        mt: 1,
                        width: '100%',
                        maxWidth: 400,
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Box sx={{ mb: 4 }}>
                        <img src={mode === 'light' ? 'logo.svg' : 'logo_dark.svg'} alt="Logo" style={{ width: '100px', padding: 5 }} />
                    </Box>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Box sx={{ height: '48px', mt: 2 }}>
                        {error && (
                            <Typography color="error">
                                {error}
                            </Typography>
                        )}
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mb: 2 }}
                        disabled={loading}
                    >
                        {loading ? 'Signing In...' : 'Sign In'}
                    </Button>
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    padding: 0,
                }}
            >
                <Box
                    component="img"
                    src={mode === 'light' ? "sparrow_background.webp" : "sparrow_background_dark.webp"}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default AuthenticationPage
