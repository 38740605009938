import { useEffect, useRef, useCallback } from 'react';

const useBottomDetection = (onBottomReached, options = {}) => {
  const containerRef = useRef(null);
  const observerRef = useRef(null);

  const handleIntersection = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        onBottomReached();
      }
    },
    [onBottomReached]
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const { bottomOffset = 0 } = options;

    observerRef.current = new IntersectionObserver(handleIntersection, {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0,
      ...options,
    });

    // Create a sentinel element to observe at the bottom of the container
    const sentinel = document.createElement('div');
    sentinel.style.position = 'relative'; // Make the sentinel relative to the container
    sentinel.style.height = '0px'; // Minimal height
    sentinel.style.opacity = '0';
    sentinel.style.pointerEvents = 'none';
    sentinel.style.width = '100%';
    sentinel.style.bottom = `${bottomOffset}px`; // Position it 100px above the bottom
    container.appendChild(sentinel);

    observerRef.current.observe(sentinel);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      container.removeChild(sentinel);
    };
  }, [handleIntersection, options]);

  return containerRef;
};

export default useBottomDetection;