import { Box, CssBaseline } from '@mui/material';
import Sidebar from './components/Sidebar';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useState } from 'react';
import MainContent from './components/MainContent';
import { useAuth } from './context/AuthProvider';

function App() {
  const sidebarWidth = 100;

  const { authState } = useAuth();

  return (
    <>
      <CssBaseline />
      <Router>
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
          {authState && (
            <Box sx={{ width: sidebarWidth, flexShrink: 0 }}>
              <Sidebar width={sidebarWidth} />
            </Box>
          )}
          <MainContent />
        </Box>
      </Router>
    </>
  );
}

export default App;
