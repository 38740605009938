import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import NotificationRow from '../components/NotificationRow';

const NotificationsPage = () => {
    const [notifications, setNotifications] = useState([
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
        // { title: 'Failed cron execution', description: 'Hawk Eye | BRZ Failed to execute', date: '2024-08-05 12:00:00' },
    ]);

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            gap: 5,
            p: 5,
            height: '100vh', // Adjust this value based on your layout
            overflow: 'hidden', // Prevent scrolling on the main container
        }}>

            <Paper 
                elevation={4} 
                sx={{ 
                    flexGrow: 3, 
                    flexBasis: 0, 
                    borderRadius: 4, 
                    // p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%', // Set the Paper to full height
                    overflow: 'hidden', // Prevent scrolling on the Paper
                }}
            >
                <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h5" style={{ fontWeight: 'bold' }}>Notifications</Typography>
                </Box>
                <TableContainer sx={{ flexGrow: 1, overflow: 'auto' }}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>TITLE</TableCell>
                                <TableCell>DESCRIPTION</TableCell>
                                <TableCell>DATE</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notifications.map((notification, index) => (
                                <NotificationRow key={index} {...notification} index={index} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}

export default NotificationsPage
