import { TableCell, TableRow } from "@mui/material";
import StyledTableRow from "./StyledTableRow";

const NotificationRow = ({ index, title, description, date }) => {

  const isOdd = index % 2 !== 0;

  return (
    <StyledTableRow sx={{ height: '50px' }} isOdd={isOdd} >
      <TableCell>{title}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>{date}</TableCell>
    </StyledTableRow>
  );
};

export default NotificationRow;