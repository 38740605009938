import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Autocomplete, Box, TextField } from '@mui/material';

const TimezoneSelector = ({ name, value, onChange }) => {
    const [timezones, setTimezones] = useState([]);

    useEffect(() => {
        const allTimezones = moment.tz.names();
        const sortedTimezones = allTimezones.sort((a, b) => a.localeCompare(b));
        setTimezones(sortedTimezones);
    }, []);

    const handleChange = (event, newValue) => {
        onChange({
            target: {
                name,
                value: newValue
            }
        });
    }

    return (
        <Box sx={{ my: 2 }}>
            <Autocomplete
                margin="normal"
                value={value}
                onChange={handleChange}
                options={timezones}
                renderInput={(params) => <TextField {...params} label="Timezone" />}
                fullWidth
                disablePortal={false}
                autoHighlight
                blurOnSelect
            />
        </Box>
    );
}

export default TimezoneSelector;