import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, styled, TableCell, TableRow, TextField, Typography } from "@mui/material";
import PauseIcon from '@mui/icons-material/Pause';
import { useEffect, useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import StyledTableRow from "./StyledTableRow";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import TimezoneSelector from "./TimezoneSelector";
import cronstrue from 'cronstrue';
import { formatAgo } from "../utils/format";

const tableCellStyle = { 
    whiteSpace: 'nowrap', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    maxWidth: '200px' 
}

const CronRow = ({ index, cron, selected, onClick, onDeleteClick, onSaveClick, onActiveToggle }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [cronData, setCronData] = useState(cron);
    const [isSaving, setIsSaving] = useState(false);
    const [exprDesc, setExprDesc] = useState(null);

    var formattedLastExecution = 'No Data';

    if (cron?.lastExecution) {
      try {
        formattedLastExecution = formatAgo(cron.lastExecution, null);
      }catch(error) {
        formattedLastExecution = 'Invalid Date';
      }
    }

    const getExpressionDescription = (expression) => {
        try {
            return cronstrue.toString(expression ?? '');
        } catch(error) {
            return 'Invalid expression';
        }
    };
    
    const toggleOpen = (e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    const toggleCronActive = async(e) => {
        e.stopPropagation();

        let cron = cronData;
        cron.active = !cron.active;
        
        setCronData(cron);
        await onActiveToggle(cron.id, cron.active);  
    }

    const isOdd = index % 2 !== 0;

    //
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmDelete = () => {
        onDeleteClick();
        handleClose();
    };

    const [editErrors, setEditErrors] = useState({
        name: false,
        expression: false,
        timezone: false,
        url: false,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCronData(prev => ({ ...prev, [name]: value }));
        setEditErrors(prevErrors => ({ ...prevErrors, [name]: false }));
    };


    const handleSave = async() => {
        setIsSaving(true);
        //wait 0.5s
        await new Promise((resolve) => setTimeout(resolve, 500));
        try {
            await onSaveClick(cronData);   
        }catch(error) {
            if (error.response && error.response.data && error.response.data.errors) {
                const serverErrors = error.response.data.errors;
                const newErrors = { ...editErrors };
                
                serverErrors.forEach(err => {
                    if (newErrors.hasOwnProperty(err.path)) {
                        newErrors[err.path] = err.msg;
                    }
                });
    
                setEditErrors(newErrors);
            } else {
                // Handle unexpected errors
                console.error('An unexpected error occurred:', error);
            }
        }finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        try {
            setExprDesc(cronstrue.toString(cronData?.expression ?? ''));
        }catch(error) {
            setExprDesc('Invalid expression');
        }
    }, [cronData]);

    return (
        <>
            <StyledTableRow hover sx={{ height: '50px', }} isOdd={isOdd} selected={selected} onClick={onClick}>
                <TableCell sx={tableCellStyle}>
                    <IconButton
                        size="small"
                        onClick={toggleOpen}
                        sx={{
                            transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                            transition: 'transform 0.3s ease-in-out',
                            marginRight: '8px',
                        }}
                    >
                        <ArrowDropDownIcon />
                    </IconButton>
                    {cron?.name ?? 'No Data'}
                </TableCell>
                <TableCell sx={tableCellStyle}>{getExpressionDescription(cron?.expression)}</TableCell>
                <TableCell sx={tableCellStyle}>{formattedLastExecution}</TableCell>
                <TableCell sx={tableCellStyle}>
                    <IconButton
                        size="small"
                        onClick={toggleCronActive}
                        sx={{ color: 'primary.main' }}
                        title={cron?.active ? "Pause this cron" : "Start this cron"}
                    >
                        {cron?.active ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                </TableCell>
            </StyledTableRow>
            <TableRow>
                <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0, margin: 0 }}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <div style={{ padding: '16px' }}>
                            <Box mb={2}>
                                <Typography variant="subtitle2" gutterBottom>Cron ID</Typography>
                                <Typography variant="subtitle2" gutterBottom>{cron?.id ?? 'No Data'}</Typography>
                            </Box>

                            <Box mb={2}>
                                <Typography variant="subtitle2" gutterBottom>Cron Name</Typography>
                                <TextField 
                                    fullWidth 
                                    variant="outlined" 
                                    size="small" 
                                    name="name"
                                    value={cronData?.name ?? ''} 
                                    onChange={handleInputChange}
                                    error={editErrors.name}
                                    margin="none" 
                                />
                            </Box>

                            <Box mb={2}>
                                <Typography variant="subtitle2" gutterBottom>Cron Expression</Typography>
                                <TextField 
                                    fullWidth 
                                    variant="outlined" 
                                    size="small" 
                                    name="expression"
                                    value={cronData?.expression ?? ''} 
                                    onChange={handleInputChange}
                                    error={editErrors.expression}
                                    placeholder="* * * * *" 
                                    helperText={exprDesc}
                                    margin="none" 
                                />
                            </Box>

                            <Box mb={2}>
                                <Typography variant="subtitle2" gutterBottom>Timezone</Typography>
                                <TimezoneSelector 
                                    name="timezone"
                                    value={cronData?.timezone ?? ''} 
                                    onChange={handleInputChange} 
                                    error={editErrors.timezone}
                                />
                            </Box>

                            <Box mb={5}>
                                <Typography variant="subtitle2" gutterBottom>Cron URL</Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name="url"
                                    value={cronData?.url ?? ''}
                                    onChange={handleInputChange}
                                    error={editErrors.url}
                                    margin="none"
                                />
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, mt: 2 }}>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={handleClickOpen}
                                    startIcon={<DeleteIcon />}
                                >
                                    Delete
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                    disabled={isSaving}
                                    startIcon={<SaveIcon />}
                                >
                                    {isSaving ? 'Saving...' : 'Save'}
                                </Button>

                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Confirm Delete"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to delete this item?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose}>Cancel</Button>
                                        <Button onClick={handleConfirmDelete} color="error" autoFocus>
                                            Delete
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>

    );
};

export default CronRow;